import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import { getHeaders } from '../../../request';
import { batch, useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import usuario from '../../../signals/usuario';
import { idEvento } from '../../../signals/eventos';
import alerta from "../../../signals/alerta";

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        bottom: 0,
        top: 0,
        height: '100%',
        width: '100%',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        padding: 0,
        overflowY: 'hidden',
        overflowX: 'hidden',
        ...theme.palette.paper
    },
}));

const VideoScreenBBB = ({ eventoAberto }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const joinUrl = useSignal(false);
    const erroConectarBBB = useSignal(false);

    useEffect(() => {
        joinSession();
        eventoAberto.value = false;

        return () => {
            eventoAberto.value = true;
        }
    }, []);

    const joinSession = () => {
        const dadosPost = {
            nome: usuario.value.nome,
            idFuncionario: usuario.value.idFuncionario,
            idEvento: idEvento.value
        }
        axios.post(`${global.gConfig.url_base_conecta}/evento/acesso-bbb`, dadosPost, { headers: getHeaders() })
            .then(function (response) {
                const { data } = response;

                // É necessário aguardar 5 segundos para conectar o usuário na sala.
                // Seguindo a recomendação de: https://www.bigbluemeeting.com/docs/iframe
                setTimeout(() => {
                    joinUrl.value = data.joinUrl;
                }, 5000);
            })
            .catch(function (error) {
                erroConectarBBB.value = true;
                alerta.value = {
                    ...alerta.value,
                    open: true,
                    type: 'alert',
                    title: strings.atencao,
                    message: strings.erroConectarBBB,
                    options: [
                        {
                            title: strings.sim,
                            onClick: () => {
                                joinSession();
                                batch(() => {
                                    erroConectarBBB.value = false;
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                });
                            }
                        },
                        {
                            title: strings.nao,
                            onClick: () => {
                                batch(() => {
                                    erroConectarBBB.value = false;
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                });
                            }
                        }
                    ],
                    onClose: () => {
                        batch(() => {
                            erroConectarBBB.value = false;
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        });
                    }
                }
                console.log(error)
            });
    }

    return (
        <div className={classes.videoContainer}>
            {
                (joinUrl.value && !erroConectarBBB.value)
                    ?
                    <iframe
                        src={joinUrl.value}
                        width="100%"
                        height="100%"
                        allow="camera *;microphone *;display-capture *;"
                        allowFullScreen
                        title={strings.tituloJanela}></iframe>
                    :
                    <SttLoading
                        open={true}
                        text={strings.aguardeSalaVideo}
                    />
            }
        </div>
    );
};

export default VideoScreenBBB;