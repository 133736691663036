
import React, { useContext } from "react";
import { SttTranslateHook } from '@stt-componentes/core';
import socket from "../../signals/socket";
import { EVENTOS_SOCKET } from "../../common/Constants";
import { listaUsuariosAberta } from "../../signals/eventos";
import { useSignalEffect, useSignals, useSignal } from "@preact/signals-react/runtime";
import { SttListaPresenca } from '@stt-componentes/atendimento';
import { getHeaders } from "../../request";

const PainelEsquerda = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const listaUsuarios = useSignal([]);

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.LISTA_PRESENCA, (dados) => {
                dados = JSON.parse(dados);
                listaUsuarios.value = dados;
            });
        }
    });

    return (
        <SttListaPresenca
            listaAberta={listaUsuariosAberta}
            lista={listaUsuarios}
            strings={strings}
            headers={getHeaders()}
        />
    );
}

export default PainelEsquerda;