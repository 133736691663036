import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttCircularProgress,
    SttCacheManager,
    SttTranslateHook
} from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';
import usuario from '../signals/usuario';
import { useSignals } from '@preact/signals-react/runtime';
import MenuEvento from '../componentes/menu-evento';
import { useEffect } from 'react';
import alerta from '../signals/alerta';
import { useContext } from 'react';
import HomeEvento from '../componentes/evento';

const useStyles = makeStyles(theme => ({
    wrapper: {
        // paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const InternoEvento = ({ id }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const redirectInicio = () => window.location.href = `${globalThis.gConfig.url_base_callback}/inicio`;

    useEffect(() => {
        if (!id) {
            alerta.value = {
                open: true,
                title: strings.atencao,
                type: 'alert',
                message: strings.idObrigatorio,
                options: [
                    {
                        title: strings.ok,
                        onClick: redirectInicio,
                    }

                ],
                onClose: redirectInicio
            }
            return;
        }
        try {
            screen.orientation && screen.orientation.lock('portrait').catch((error) => {
                console.log('Navegador não suporta bloqueio de orientação de janela');
                console.log(error)
            });
        } catch (error) {
            console.log('Navegador não suporta bloqueio de orientação de janela');
            console.log(error)
        }
    }, [id]);

    return (
        usuario.value.autenticado
            ?
            <div className={classes.wrapper}>
                <MenuEvento />
                <HomeEvento id={id} />
            </div>
            :
            <>
                <div className={classes.loading}>
                    <SttCircularProgress />
                </div>
                <SttCacheManager config={global.gConfig} onSync={loaded => {
                    usuario.value = { ...Cache.getUserInfo(), autenticado: loaded };
                }} />
            </>
    );

};

export default InternoEvento;