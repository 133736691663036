import React, { useEffect, lazy, Suspense, memo, useContext } from 'react';
import {
    SttTabs,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));

const ConviteSuspense = memo((props) => {
    // const Convite = lazy(() => import('../../componentes/inicio/convites'));

    // return (
    //     <Suspense fallback={<SttCircularProgress color="primary" />}>
    //         <Convite {...props} />
    //     </Suspense>
    // )
    return <div>aba convite</div>
});

const AoVivoSuspense = memo((props) => {
    // const AoVivo = lazy(() => import('../../componentes/inicio/ao-vivo'));

    // return (
    //     <Suspense fallback={<SttCircularProgress color="primary" />}>
    //         <AoVivo {...props} />
    //     </Suspense>
    // )
    return <div>aba ao vivo</div>
});

const ProximasSuspense = memo((props) => {
    const Proximas = lazy(() => import('../../componentes/inicio/pesquisa'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Proximas {...props} />
        </Suspense>
    )
});

const FinalizadasSuspense = memo((props) => {
    const Finalizadas = lazy(() => import('../../componentes/inicio/pesquisa'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Finalizadas {...props} buscarFinalizadas={true} />
        </Suspense>
    )
});


const IndexInicio = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const abas = useSignal([]);
    const abaAtiva = useSignal('0');

    useEffect(() => {
        abas.value = [
            // {
            //     titulo: strings.convites,
            //     conteudo: ConviteSuspense,
            //     key: 'ConviteSuspense',
            //     permanente: true
            // },
            // {
            //     titulo: strings.aoVivo,
            //     conteudo: AoVivoSuspense,
            //     key: 'AoVivoSuspense',
            //     permanente: true
            // },
            {
                titulo: strings.proximosEventos,
                conteudo: ProximasSuspense,
                key: 'ProximasSuspense',
                permanente: true
            },
            {
                titulo: strings.eventosPassados,
                conteudo: FinalizadasSuspense,
                key: 'FinalizadasSuspense',
                permanente: true
            }
        ];
    }, []);

    return (
        <SttTabs abas={abas.value}
            canClose={true}
            className={classes.wrapper}
            abaAtiva={abaAtiva.value}
            permanente={true}
            preload={false}
        />
    );
};

export default memo(IndexInicio);