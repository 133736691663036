import React, {} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SttHeading from '@stt-componentes/core/dist/lib/SttTypography/SttHeading';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));

const Erro = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <SttHeading variant="h1">
                <ErrorOutlineIcon />
                Erro!
            </SttHeading>
            <SttHeading variant="h4">
                Ocorreu um erro desconhecido. Por favor, entre em contato com o seu suporte.
            </SttHeading>
        </div>
    );
};

export default Erro;
