import * as yup from 'yup';
import { ASSUNTO, CATEGORIA, DATA_HORA_INICIO, PARTICIPANTES, REDE, TEMPO_DURACAO } from './field-names';

export default (strings) => {
    return yup.object().shape({
        [REDE]: yup
            .object()
            .nullable()
            .required(strings.campoObrigatorio),
        [CATEGORIA]: yup
            .object()
            .nullable()
            .required(strings.campoObrigatorio),
        [ASSUNTO]: yup
            .string()
            .trim()
            .min(5, strings.erroAssuntoMinimo5Caracteres)
            .max(255)
            .nullable()
            .required(strings.campoObrigatorio),
        [DATA_HORA_INICIO]: yup
            .date()
            .typeError(strings.dataInvalida)
            .min(new Date(), strings.erroDataPassada)
            .nullable()
            .required(strings.campoObrigatorio),
        [TEMPO_DURACAO]: yup
            .number()
            .min(10, strings.minimo10Minutos)
            .max(360, strings.maximo360Minutos)
            .nullable()
            .required(strings.campoObrigatorio),
        [PARTICIPANTES]: yup
            .array()
            .min(1, strings.erroParticipanteObrigatorio)
            .required()

    });
}