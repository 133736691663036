import React, { useContext } from "react";
import {
    SttBox,
    SttHidden,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignal, useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import {
    atendimentoEncerrado,
    emChamadaVideo
} from "../../signals/eventos";
import { EVENTOS_SOCKET } from "../../common/Constants";
import socket, { erroConexacaoSocket } from "../../signals/socket";
import ChatIcon from '@material-ui/icons/Chat';
import Janela from "./janela";
import VideoScreenBBB from "./video/bbb";

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        display: 'flex',
        overflow: 'hidden'
    },
    chatBox: {
        height: '100%',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    videoBox: {
        height: '100%',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    historicoBox: {
        overflowY: 'auto',
        overflowX: 'hidden',
        width: (props) => props.widthChat,
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        maxWidth: '100%',
        width: '100%',
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaper: {
        top: 'auto',
        maxWidth: '100%',
        width: '100%'
    },
    openChatButton: {
        padding: theme.spacing(1.5),
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            top: 106,
        },
        zIndex: 10,
        borderRadius: '0 0 15px 0',
        backgroundColor: 'white'
    },
}));

const Painel = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    let widthChat = '100%';
    if (emChamadaVideo.value) {
        widthChat = '50%';
    }
    const classes = useStyles({ widthChat, erroSocket: erroConexacaoSocket.value });
    useSignals();

    const eventoAberto = useSignal(true);

    useSignalEffect(() => {
        if (socket.value && !atendimentoEncerrado.value) {
            socket.value.on(EVENTOS_SOCKET.STATUS_ATENDIMENTO_VIDEO, (dados) => {
                dados = JSON.parse(dados);
                emChamadaVideo.value = dados.videoAtivo;
            });
        }
    });

    return (
        <SttBox className={classes.wrapper}>
            {/* Container de mensagens */}
            <SttHidden xsDown>
                <SttBox className={classes.chatBox}
                    sx={{ width: widthChat }}>
                    <Janela />
                </SttBox>
                <SttBox sx={{ border: ".05px solid #e9e9e9" }} />
            </SttHidden>

            <SttHidden smUp>
                {
                    !eventoAberto.value &&
                    <Tooltip title={strings.chat}>
                        <IconButton disableRipple={true} className={classes.openChatButton} onClick={() => eventoAberto.value = true}>
                            <ChatIcon />
                        </IconButton>
                    </Tooltip>
                }
                <SttBox className={classes.chatBox}
                    sx={{ width: eventoAberto.value ? '100%' : '0px' }}>
                    <Janela eventoAberto={eventoAberto} />
                </SttBox>
            </SttHidden>

            {
                emChamadaVideo.value &&
                <>
                    <SttHidden xsDown>
                        <SttBox className={classes.videoBox}
                            sx={{ width: widthChat }}>
                            <VideoScreenBBB eventoAberto={eventoAberto} />
                        </SttBox>
                        <SttBox sx={{
                            border: ".05px solid #e9e9e9",
                        }} />
                    </SttHidden>

                    <SttHidden smUp>
                        <SttBox className={classes.videoBox}
                            sx={{ width: eventoAberto.value ? '0px' : '100%' }}>
                            <VideoScreenBBB eventoAberto={eventoAberto} />
                        </SttBox>
                    </SttHidden>
                </>
            }
        </SttBox>
    );
}

export default Painel;