export const mudarNomeTab = (nome) => {
    document.title = nome;
}

export const pedirPermissaoNotificacao = (exibirNotificacao = () => { }) => {
    if (!("Notification" in window)) return;

    if (Notification.permission === 'granted') {
        // Permissão já concedida
        exibirNotificacao();
    } else if (Notification.permission !== 'denied') {
        // Pedir permissão ao usuário
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                exibirNotificacao();
            }
        });
    }
}

export const resetTab = () => {
    mudarNomeTab(global.gConfig.titulo_aplicacao);
}