import React, { } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SttHeading from '@stt-componentes/core/dist/lib/SttTypography/SttHeading';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
    SttText,
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));

const Manutencao = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <SttHeading variant="h1">
                <ErrorOutlineIcon />
                Em Manutenção!
            </SttHeading>
            <SttText>O módulo Conecta está em manutenção. Em breve o sistema retornará ao seu funcionamento normal.</SttText>
            <SttText>Período de manutenção previsto: {global.gConfig.manutencao_previsao}</SttText>
        </div>
    );
};

export default Manutencao;
