"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CONSTANTES = void 0;
var CONSTANTES = exports.CONSTANTES = {
  SEM_RESULTADO: 0,
  EXTENSAO_IMAGEM_JPG: 'jpg',
  EXTENSAO_IMAGEM_JPEG: 'jpeg',
  EXTENSAO_IMAGEM_PNG: 'png',
  EXTENSAO_IMAGEM_BMP: 'bmp'
};