import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useHistory } from "react-router-dom";
import {
    SttHeader,
    SttMainNavigationBar,
    SttSplashScreen,
    MateriaisSuporte,
    SttTranslateHook,
    SttNotificationScreen,
    SttHelpdeskShortcut,
} from '@stt-componentes/core';
import { temPermissaoRBAC } from '../security/rbac';
import { PERMISSOES } from '../common/Constants';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { getHeaders } from '../request';
import { signal } from '@preact/signals-react';
import usuario from '../signals/usuario';

const submenus = signal([]);
const tabAtual = signal(0);
const openMS = signal(false);
const openSS = signal(false);

const Menu = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const location = useLocation();
    const navigate = useNavigate();

    const exibirNotificacao = useSignal(false);

    useSignalEffect(() => {
        let menuOptions = [];
        if (usuario.value) {
            //Tratamento de permissão do usuário
            if (!usuario.value || !usuario.value.idFuncionario) {
                navigate(`/nao-autorizado`);
                return;
            }
            exibirNotificacao.value = true;

            menuOptions.push({
                id: '/inicio',
                text: strings.inicio,
                onClick: () => {
                    navigate("/inicio");
                }
            })

            if (temPermissaoRBAC(usuario, PERMISSOES.ADMINISTRATIVO)) {
                menuOptions.push({
                    id: '/administrativo',
                    text: strings.administrativo,
                    onClick: () => {
                        navigate("/administrativo");
                    }
                });
            }
            submenus.value = menuOptions;
        }
    });

    useEffect(() => {
        submenus.value.forEach((menu, i) => {
            if (location.pathname.includes(menu.id)) {
                tabAtual.value = i;
            }
        });
    }, [location]);

    return (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                config={global.gConfig}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => openSS.value = !openSS.value,
                    onClickMateriaisSuporte: () => openMS.value = !openMS.value,
                    strings
                }}
            />
            <SttMainNavigationBar
                config={global.gConfig}
                titulo={global.gConfig.modulo_nome}
                submenus={submenus.value}
                selectedTab={tabAtual.value}
                callbackNavigationBar={(event, newTab) => { }}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                textoAjuda={global.gConfig.texto_ajuda}
                perfisRBAC={usuario.value.perfisRBAC}
                open={openSS.value}
                setOpen={(open) => { openSS.value = open }}
            />
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS.value}
                setOpen={(open) => { openMS.value = open }}
            />
            {
                exibirNotificacao.value &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={usuario.value.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={usuario.value}
            />
        </div>
    );
};

export default Menu;