import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Routes, Route } from 'react-router-dom';
import Menu from '../componentes/menu';
import {
    SttCircularProgress,
    SttCacheManager
} from '@stt-componentes/core';
import Administrativo from './administrativo';
import Inicio from './inicio';
import Erro401 from './erro/401';
import { Cache } from '@stt-componentes/cache';
import usuario from '../signals/usuario';
import { useSignals } from '@preact/signals-react/runtime';
import Agendamento from '../componentes/inicio/agendamento';
import NotificacaoEventos from '../componentes/notificacao';
import { idAgendamentoDetalhe } from '../signals/agendamento';
import DetalheAgendamento from '../componentes/inicio/agendamento/detalhe';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Interno = () => {
    const classes = useStyles();
    useSignals();

    return (
        usuario.value.autenticado
            ?
            <div className={classes.wrapper}>
                <Menu />
                <Routes>
                    <Route path={'/'} exact element={<Inicio />} />
                    <Route path={'/inicio'} exact element={<Inicio />} />
                    <Route path={'/agendamento'} exact element={<Agendamento />} />
                    <Route path="/nao-autorizado" exact element={<Erro401 />} />
                    <Route path="/administrativo" exact element={<Administrativo />} />
                </Routes>

                <NotificacaoEventos />

                {
                    idAgendamentoDetalhe.value &&
                    <DetalheAgendamento />
                }
            </div>
            :
            <>
                <div className={classes.loading}>
                    <SttCircularProgress />
                </div>
                <SttCacheManager config={global.gConfig} onSync={loaded => {
                    usuario.value = { ...Cache.getUserInfo(), autenticado: loaded };
                }} />
            </>
    );

};

export default Interno;