import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Draggable from "react-draggable";
import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import {
    SttAlertTitle,
    SttBox,
    SttButton,
    SttHeading,
    SttNotification,
    SttSeeButton,
    SttTranslateHook
} from '@stt-componentes/core';
import axios from 'axios';
import { getHeaders } from '../../request';
import {
    Card,
    CardContent,
    CardHeader,
    Collapse,
    IconButton
} from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment'
import { idAgendamentoDetalhe } from '../../signals/agendamento';
import { SITUACAO_AGENDAMENTO } from '../../common/Constants';
import usuario from '../../signals/usuario';

const useStyles = makeStyles(theme => ({
    box: {
        position: 'fixed',
        display: 'flex',
        padding: 0,
        spacing: 0,
        border: '1px solid #d3d4d5',
        boxShadow: '4px 4px 4px 2px rgba(158,158,158,0.8)',
        right: 20,
        bottom: 20,
        zIndex: 1000,
        borderRadius: theme.spacing(0.5)
    },
    root: {
        maxWidth: '100%',
        cursor: 'grab'
    },
    content: {
        maxHeight: '270px',
        overflowY: 'auto',
        padding: theme.spacing(2),
        "&:last-child": {
            padding: theme.spacing(2),
        }
    },
    expand: {
        transform: 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
    notification: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        wordBreak: 'break-word',
        maxWidth: theme.spacing(40),
        fontSize: '0.8rem'
    },
    alert: {
        margin: 0,
        marginBottom: theme.spacing(1),
        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        "& .MuiAlert-icon": {
            marginRight: theme.spacing(1)
        },
        "& .MuiAlert-message": {
            width: '100%'
        }
    },
    cardHeader: {
        padding: theme.spacing(0.2),
        paddingLeft: theme.spacing(1.5),
        borderBottom: '1px solid #d3d4d5',
        '& .MuiTypography-displayBlock': {
            display: 'block',
            fontWeight: 800
        }
    },
    button: {
        marginTop: theme.spacing(1)
    },
    alertTitle: {
        fontWeight: 800,
        wordBreak: 'break-word',
        maxWidth: theme.spacing(35),
        fontSize: '0.8rem'
    },
    divTitulo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    }
}));

const NotificacaoEventos = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals()

    const agendamentos = useSignal([]);
    const expandido = useSignal(true);

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_conecta}/agendamento`, { headers: getHeaders() })
            .then((response) => {
                response.data.itens = response.data.itens.filter(
                    i => [
                        SITUACAO_AGENDAMENTO.SITUACAO.CONVITES_ENVIADOS,
                        SITUACAO_AGENDAMENTO.SITUACAO.CONFIRMADO,
                        SITUACAO_AGENDAMENTO.SITUACAO.REAGENDADO,
                        SITUACAO_AGENDAMENTO.SITUACAO.EM_ANDAMENTO
                    ].includes(i.situacao)
                )
                agendamentos.value = response.data.itens;
            })
            .catch(err => {
                agendamentos.value = [];
            })
    }, []);

    const handleExpandClick = () => {
        expandido.value = !expandido.value;
    }

    if (agendamentos.value.length === 0) {
        return null;
    }

    return (
        <Draggable cancel=".notDragabble" bounds="html">
            <SttBox className={classes.box}>
                <Card className={classes.root}>
                    <div className='divDragabble'>
                        <CardHeader className={classes.cardHeader}
                            action={
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expandido.value,
                                    })}
                                    onClick={handleExpandClick}
                                    onTouchStart={handleExpandClick}
                                    aria-expanded={expandido.value}
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            }
                            title={strings.proximosEventos}
                            titleTypographyProps={{
                                variant: 'h3'
                            }}
                        />
                    </div>
                    <Collapse in={expandido.value} timeout="auto" unmountOnExit orientation='vertical' >
                        <CardContent className={classes.content}>
                            <div className='notDragabble' style={{ cursor: 'default' }}>
                                {
                                    agendamentos.value.map((agendamento, index) => {
                                        const agora = moment();
                                        const inicioMenos5 = moment(agendamento.dataHoraInicio).subtract(5, 'minutes');
                                        const acessivel = agora.isAfter(inicioMenos5);
                                        const dataFormatada = moment(agendamento.dataHoraInicio).format('DD/MM/YYYY HH:mm');

                                        return (
                                            <SttNotification severity={acessivel ? 'warning' : 'info'} className={classes.alert} key={index}>
                                                <div className={classes.divTitulo}>
                                                    <SttAlertTitle className={classes.alertTitle}>{agendamento.assunto}</SttAlertTitle>
                                                    <SttSeeButton onClick={() => idAgendamentoDetalhe.value = agendamento._id} />
                                                </div>
                                                <SttHeading variant="h5"
                                                    color={acessivel ? 'text' : 'secondary'}
                                                    className={classes.heading}>
                                                    <b>{strings.dataHora}:</b> {dataFormatada}
                                                </SttHeading>
                                                <SttHeading variant="h5"
                                                    color={acessivel ? 'text' : 'secondary'}
                                                    className={classes.heading}>
                                                    <b>{strings.rede}:</b> {agendamento.rede.descricao}
                                                </SttHeading>
                                                <SttHeading variant="h5"
                                                    color={acessivel ? 'text' : 'secondary'}
                                                    className={classes.heading}>
                                                    <b>{strings.categoria}:</b> {agendamento.categoria.descricao}
                                                </SttHeading>
                                                {
                                                    (
                                                        agendamento.participantes.some(p => p.aceito && p.funcionario.id === usuario.value.idFuncionario) ||
                                                        agendamento.agendador.funcionario.id === usuario.value.idFuncionario
                                                    ) &&
                                                    (
                                                        [SITUACAO_AGENDAMENTO.SITUACAO.CONFIRMADO, SITUACAO_AGENDAMENTO.SITUACAO.EM_ANDAMENTO].includes(agendamento.situacao) && acessivel ||
                                                        agendamento.situacao === SITUACAO_AGENDAMENTO.SITUACAO.FINALIZADO
                                                    ) &&
                                                    <SttButton variant="contained"
                                                        color="secondary"
                                                        nomarginleft="true"
                                                        style={{ marginTop: 4 }}
                                                        onClick={() => {
                                                            window.open(`${globalThis.gConfig.url_base_callback}/evento/${agendamento._id}`)
                                                        }}>
                                                        {strings.entrar}
                                                    </SttButton>
                                                }
                                            </SttNotification>
                                        )
                                    })
                                }
                            </div>
                        </CardContent>
                    </Collapse>
                </Card>
            </SttBox>
        </Draggable>
    );
};

export default NotificacaoEventos;