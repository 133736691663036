import { signal } from "@preact/signals-react";

export const agendamentoEvento = signal(); // <AGENDAMENTO>
export const idEvento = signal(); // <ID_EVENTO>
export const registroAberto = signal(false); // Registro do atendimento está sendo editado por algum usuário?
export const atendimentoEncerrado = signal(false);
export const idSalaAtendimento = signal(); // ID SALA NO SERVIDOR SOCKET.IO -> sala_atendimento_<ID_EVENTO>
export const emChamadaVideo = signal(false);
export const quantidadeMembrosSala = signal(0);
export const registroEvento = signal();
export const listaUsuariosAberta = signal(false);