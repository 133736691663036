"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SITUACOES_ATENDIMENTO = void 0;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var situacaoAtendimento = {
  ESPERA: 0,
  SOLICITOU_ATENDIMENTO: 5,
  EM_ATENDIMENTO: 10,
  ENCAMINHADO: 15,
  CONCLUIDO: 20,
  HISTORICO: 25
};
var SITUACOES_ATENDIMENTO = exports.SITUACOES_ATENDIMENTO = {
  SITUACAO: _objectSpread({}, situacaoAtendimento),
  CHIP: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, situacaoAtendimento.ESPERA, {
    descricao: 'Disponível',
    cor: '#FFE599'
  }), situacaoAtendimento.SOLICITOU_ATENDIMENTO, {
    descricao: 'Solicitado',
    cor: '#E06666'
  }), situacaoAtendimento.EM_ATENDIMENTO, {
    descricao: 'Ativo',
    cor: '#70C1B3'
  }), situacaoAtendimento.ENCAMINHADO, {
    descricao: 'Encaminhado',
    cor: '#92A8D1'
  }), situacaoAtendimento.CONCLUIDO, {
    descricao: 'Concluído',
    cor: '#D9D9D9'
  }), situacaoAtendimento.HISTORICO, {
    descricao: 'Histórico',
    cor: '#6D6875'
  })
};