import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttModal,
    SttTranslateHook,
    SttContainer,
    SttTable,
    SttTableHead,
    SttTableRow,
    SttTableCell,
    SttTableBody,
    SttGrid,
    SttLoading,
    SttCircularProgress,
} from '@stt-componentes/core';
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import moment from 'moment'
import { Chip } from "@material-ui/core";
import { PERMISSOES, SITUACAO_AGENDAMENTO } from "../../../common/Constants";
import { agendamentoAlteracao, pesquisar, idAgendamentoDetalhe } from '../../../signals/agendamento';
import usuario from '../../../signals/usuario'
import { useNavigate } from "react-router-dom";
import { temPermissaoRBAC } from "../../../security/rbac";
import alerta from "../../../signals/alerta";
import HttpStatus from 'http-status-codes';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { batch } from "@preact/signals-react";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(2)
    },
    headerButton: {
        marginTop: theme.spacing(2)
    },
    notificacao: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginBottom: theme.spacing(1)
    },
    chipSituacao: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        height: 'auto',
        '& span': {
            whiteSpace: 'pre-wrap'
        }
    }
}));

const textoSituacaoConvite = (convite, strings) => {
    if (convite.aceito === null) return strings.convitePendente;
    if (convite.aceito) return strings.conviteAceito;
    return strings.conviteNegado;
}

const DetalheAgendamento = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const navigate = useNavigate();
    useSignals();

    const salvando = useSignal(false);
    const agendamento = useSignal();

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_conecta}/agendamento/${idAgendamentoDetalhe.value}`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                agendamento.value = data.agendamento;
            })
            .catch(err => {
                console.log(err)
            });
    }, []);

    const fecharModalDetalhes = () => {
        idAgendamentoDetalhe.value = null;
    }

    const fecharAlerta = () => {
        alerta.value = {
            ...alerta.value,
            open: false
        };
    }

    const sucessoCancelar = () => {
        batch(() => {
            fecharModalDetalhes();
            fecharAlerta();
            pesquisar.value = true;
        })
    }

    const cancelarAgendamento = () => {
        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        const dados = {
            _id: agendamento.value._id
        };

        salvando.value = true;
        axios.post(`${global.gConfig.url_base_conecta}/agendamento/cancelamento`, dados, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                mensagemAlerta = strings.sucessoCancelarAgendamento;
                options = [{
                    title: strings.ok,
                    onClick: sucessoCancelar
                }];
                onClose = sucessoCancelar
            })
            .catch(err => {
                const { response } = err;
                tipoAlerta = 'error';
                tituloAlerta = strings.erro;
                mensagemAlerta = strings.erroGenerico;

                if (response) {
                    const { data } = response;

                    mensagemAlerta = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagemAlerta = arrMensagem.join('\n');
                        }
                    }
                }

                options = [{
                    title: strings.ok,
                    onClick: fecharAlerta
                }];
                onClose = fecharAlerta
            })
            .finally(() => {
                batch(() => {
                    salvando.value = false;
                    alerta.value = {
                        ...alerta.value,
                        type: tipoAlerta,
                        title: tituloAlerta,
                        message: mensagemAlerta,
                        open: true,
                        options: options,
                        onClose: onClose
                    }
                })
            });
    }

    const aceitarRecusarConvite = (aceitou) => {
        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        const dados = {
            _id: agendamento.value._id,
            aceitou
        };

        salvando.value = true;
        axios.post(`${global.gConfig.url_base_conecta}/agendamento/convite`, dados, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                mensagemAlerta = aceitou ? strings.sucessoAceitarAgendamento : strings.sucessoRecusarAgendamento;
                options = [{
                    title: strings.ok,
                    onClick: sucessoCancelar
                }];
                onClose = sucessoCancelar
            })
            .catch(err => {
                const { response } = err;
                tipoAlerta = 'error';
                tituloAlerta = strings.erro;
                mensagemAlerta = strings.erroGenerico;

                if (response) {
                    const { data } = response;

                    mensagemAlerta = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagemAlerta = arrMensagem.join('\n');
                        }
                    }
                }

                options = [{
                    title: strings.ok,
                    onClick: fecharAlerta
                }];
                onClose = fecharAlerta
            })
            .finally(() => {
                batch(() => {
                    salvando.value = false;
                    alerta.value = {
                        ...alerta.value,
                        type: tipoAlerta,
                        title: tituloAlerta,
                        message: mensagemAlerta,
                        open: true,
                        options: options,
                        onClose: onClose
                    }
                })
            });
    }

    const horaEventoAcessivel = () => {
        const agora = moment();
        const inicio = moment(agendamento.value.dataHoraInicio);
        const termino = moment(agendamento.value.dataHoraTermino);
        const horaLiberar = inicio.subtract(5, 'minutes');

        return horaLiberar.isBefore(agora) && termino.isAfter(agora);
    }

    return (
        <SttModal
            title={agendamento.value ? agendamento.value.assunto : strings.agendamento}
            open={true}
            maxWidth="lg"
            outModalClose={fecharModalDetalhes}
            iconClose={fecharModalDetalhes}
            fullWidth={true}
            children={
                <SttContainer>
                    {
                        !agendamento.value
                            ?
                            <SttCircularProgress />
                            :
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12} md={6}>
                                    <SttTable stickyHeader>
                                        <SttTableHead>
                                            <SttTableRow>
                                                <SttTableCell width="50%" align="center">{strings.data}</SttTableCell>
                                                <SttTableCell width="50%" align="center">{strings.periodo}</SttTableCell>
                                            </SttTableRow>
                                        </SttTableHead>
                                        <SttTableBody>
                                            <SttTableRow>
                                                <SttTableCell align="center">{moment(agendamento.value.dataHoraInicio).format('DD/MM/YYYY')}</SttTableCell>
                                                <SttTableCell align="center">
                                                    {moment(agendamento.value.dataHoraInicio).format('HH:mm')} - {moment(agendamento.value.dataHoraTermino).format('HH:mm')} {agendamento.value.tempoDuracao ? `(${agendamento.value.tempoDuracao} minutos)` : ''}
                                                </SttTableCell>
                                            </SttTableRow>
                                        </SttTableBody>
                                        <SttTableHead>
                                            <SttTableRow>
                                                <SttTableCell width="50%" align="center">{strings.rede}</SttTableCell>
                                                <SttTableCell width="50%" align="center">{strings.categoria}</SttTableCell>
                                            </SttTableRow>
                                        </SttTableHead>
                                        <SttTableBody>
                                            <SttTableRow>
                                                <SttTableCell align="center">{agendamento.value.rede.descricao}</SttTableCell>
                                                <SttTableCell align="center">{agendamento.value.categoria.descricao}</SttTableCell>
                                            </SttTableRow>
                                        </SttTableBody>
                                        <SttTableHead>
                                            <SttTableRow>
                                                <SttTableCell align="center">{strings.agendador}</SttTableCell>
                                                <SttTableCell align="center">{strings.situacao}</SttTableCell>
                                            </SttTableRow>
                                        </SttTableHead>
                                        <SttTableBody>
                                            <SttTableRow>
                                                <SttTableCell align="center">{agendamento.value.agendador.funcionario.nome}</SttTableCell>
                                                <SttTableCell align="center">
                                                    <Chip
                                                        className={classes.chipSituacao}
                                                        style={{ backgroundColor: SITUACAO_AGENDAMENTO.GRID[agendamento.value.situacao].cor }}
                                                        label={SITUACAO_AGENDAMENTO.GRID[agendamento.value.situacao].descricao}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        </SttTableBody>
                                    </SttTable>
                                </SttGrid>
                                <SttGrid item xs={12} md={6}>
                                    <SttTable stickyHeader>
                                        <SttTableHead>
                                            <SttTableRow>
                                                <SttTableCell align="center" colSpan={2}>{strings.convites}</SttTableCell>
                                            </SttTableRow>
                                        </SttTableHead>
                                        <SttTableBody>
                                            {
                                                agendamento.value.participantes.map(p => (
                                                    <SttTableRow key={p._id}>
                                                        <SttTableCell width="70%">{p.funcionario.nome}</SttTableCell>
                                                        <SttTableCell align="center">{textoSituacaoConvite(p, strings)}</SttTableCell>
                                                    </SttTableRow>
                                                ))
                                            }
                                        </SttTableBody>
                                    </SttTable>
                                </SttGrid>
                            </SttGrid>
                    }

                    <SttLoading open={salvando.value} text={strings.salvandoMensagemEspera} />
                </SttContainer>
            }
            footer={
                !agendamento.value
                    ?
                    <></>
                    :
                    <>
                        {
                            (temPermissaoRBAC(usuario, PERMISSOES.ADMINISTRATIVO) || agendamento.value.agendador.funcionario.id === usuario.value.idFuncionario) &&
                            [SITUACAO_AGENDAMENTO.SITUACAO.CONVITES_ENVIADOS, SITUACAO_AGENDAMENTO.SITUACAO.CONFIRMADO, SITUACAO_AGENDAMENTO.SITUACAO.REAGENDADO].includes(agendamento.value.situacao) &&
                            <>
                                <SttButton variant="contained" color="primary" onClick={() => {
                                    agendamentoAlteracao.value = {
                                        ...agendamento.value,
                                        agendador: agendamento.value.agendador.funcionario
                                    };
                                    console.log(agendamentoAlteracao.value)
                                    idAgendamentoDetalhe.value = null;
                                    navigate('/agendamento');
                                }}>
                                    {strings.alterar}
                                </SttButton>
                                <SttButton variant="contained" color="secondary" danger onClick={() => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: true,
                                        title: strings.atencao,
                                        type: 'alert',
                                        message: strings.confirmarCancelarEvento,
                                        options: [{
                                            title: strings.sim,
                                            onClick: () => {
                                                cancelarAgendamento();
                                                fecharAlerta();
                                            }
                                        },
                                        {
                                            title: strings.nao,
                                            onClick: fecharAlerta
                                        }],
                                        onClose: fecharAlerta
                                    };
                                }}>
                                    {strings.cancelar}
                                </SttButton>
                            </>
                        }

                        {
                            agendamento.value.participantes.some(p => !p.aceito && p.funcionario.id === usuario.value.idFuncionario) &&
                            [SITUACAO_AGENDAMENTO.SITUACAO.CONVITES_ENVIADOS, SITUACAO_AGENDAMENTO.SITUACAO.CONFIRMADO, SITUACAO_AGENDAMENTO.SITUACAO.REAGENDADO, SITUACAO_AGENDAMENTO.SITUACAO.EM_ANDAMENTO].includes(agendamento.value.situacao) &&
                            <SttButton variant="contained" color="primary" onClick={() => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: true,
                                    title: strings.atencao,
                                    type: 'alert',
                                    message: strings.confirmarAceitarEvento,
                                    options: [{
                                        title: strings.sim,
                                        onClick: () => {
                                            aceitarRecusarConvite(true);
                                            fecharAlerta();
                                        }
                                    },
                                    {
                                        title: strings.nao,
                                        onClick: fecharAlerta
                                    }],
                                    onClose: fecharAlerta
                                };
                            }}>
                                {strings.aceitarConvite}
                            </SttButton>
                        }

                        {
                            agendamento.value.participantes.some(p => (p.aceito || p.aceito === null) && p.funcionario.id === usuario.value.idFuncionario) &&
                            [SITUACAO_AGENDAMENTO.SITUACAO.CONVITES_ENVIADOS, SITUACAO_AGENDAMENTO.SITUACAO.CONFIRMADO, SITUACAO_AGENDAMENTO.SITUACAO.REAGENDADO, SITUACAO_AGENDAMENTO.SITUACAO.EM_ANDAMENTO].includes(agendamento.value.situacao) &&
                            <SttButton variant="contained" color="primary" danger onClick={() => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: true,
                                    title: strings.atencao,
                                    type: 'alert',
                                    message: strings.confirmarRecusarEvento,
                                    options: [{
                                        title: strings.sim,
                                        onClick: () => {
                                            aceitarRecusarConvite(false);
                                            fecharAlerta();
                                        }
                                    },
                                    {
                                        title: strings.nao,
                                        onClick: fecharAlerta
                                    }],
                                    onClose: fecharAlerta
                                };
                            }}>
                                {strings.recusarConvite}
                            </SttButton>
                        }

                        {
                            (
                                agendamento.value.participantes.some(p => p.aceito && p.funcionario.id === usuario.value.idFuncionario) ||
                                agendamento.value.agendador.funcionario.id === usuario.value.idFuncionario
                            ) &&
                            (
                                [SITUACAO_AGENDAMENTO.SITUACAO.CONFIRMADO, SITUACAO_AGENDAMENTO.SITUACAO.EM_ANDAMENTO].includes(agendamento.value.situacao) &&
                                horaEventoAcessivel() ||
                                agendamento.value.situacao === SITUACAO_AGENDAMENTO.SITUACAO.FINALIZADO
                            ) &&
                            <SttButton variant="contained" color="secondary" onClick={() => {
                                window.open(`${globalThis.gConfig.url_base_callback}/evento/${agendamento.value._id}`)
                            }}>
                                {strings.entrar}
                            </SttButton>
                        }

                        <SttButton variant="outlined" color="primary" onClick={fecharModalDetalhes}>
                            {strings.fechar}
                        </SttButton>
                    </>
            }
        />
    );
}

export default DetalheAgendamento;