import React, { useContext, useEffect } from "react";
import { SttBox, SttHidden, SttNotification, SttTranslateHook } from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core";
import usuario from "../../signals/usuario";
import socket, { erroConexacaoSocket } from "../../signals/socket";
import { EVENTOS_SOCKET, SITUACAO_AGENDAMENTO } from '../../common/Constants';
import { connect } from 'socket.io-client';
import { agendamentoEvento, atendimentoEncerrado, listaUsuariosAberta } from "../../signals/eventos";
import { getAuthorizationHeader, getHeaders } from "../../request";
import axios from "axios";
import Painel from "./painel";
import PainelEsquerda from "./painelEsquerda";
import { useSignals } from "@preact/signals-react/runtime";
import alerta from "../../signals/alerta";

const useStyles = makeStyles(theme => ({
    wrapper: {
        height: 'calc(100vh - 104px)',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 124px)',
        },
        flexDirection: 'column',
        display: 'flex',
        overflow: 'hidden'
    },
    wrapperEvento: {
        height: 'calc(100vh - 104px)',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 124px)',
        },
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        overflow: 'hidden'
    },
    notificationBox: {
        width: '100%',
        height: '100px',
        minHeight: '100px',
        marginBottom: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            height: '220px',
            minHeight: '220px',
        },
    },
    notificacao: {
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: 'larger'
    },

    fullLeftBox: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    hiddenRigthBox: {
        height: '100%',
        width: '0%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },

    leftBox: {
        height: '100%',
        width: '20%',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            width: '30%',
        },
    },
    rigthBox: {
        height: '100%',
        width: '80%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            width: '70%',
        },
    },

    shortLeftBox: {
        height: '100%',
        width: '75px',
        minWidth: '75px',
        maxWidth: '75px',
        overflow: 'hidden',
    },
    bigRigthBox: {
        height: '100%',
        width: 'calc(100% - 75px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    fullRigthBox: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
}));

const HomeEvento = ({ id }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const redirectInicio = () => window.location.href = `${globalThis.gConfig.url_base_callback}/inicio`;

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_conecta}/agendamento/${id}`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                console.log(data)
                if ([SITUACAO_AGENDAMENTO.SITUACAO.CANCELADO, SITUACAO_AGENDAMENTO.SITUACAO.CONVITES_ENVIADOS, SITUACAO_AGENDAMENTO.SITUACAO.REAGENDADO].includes(data.agendamento.situacao)) {
                    // TODO: TRATAR EVENTO NÃO AUTORIZADO A ACONTECER
                    return;
                }

                if (data.agendamento.agendador.funcionario.id !== usuario.value.idFuncionario && !data.agendamento.participantes.some(p => p.funcionario.id === usuario.value.idFuncionario)) {
                    alerta.value = {
                        open: true,
                        title: strings.atencao,
                        type: 'alert',
                        message: strings.semPermissaoEvento,
                        options: [
                            {
                                title: strings.ok,
                                onClick: redirectInicio,
                            }

                        ],
                        onClose: redirectInicio
                    }
                    return;
                }
                console.log(data.agendamento)
                agendamentoEvento.value = data.agendamento;

                iniciarConexaoWebsocket();
            })
            .catch(err => {
                alerta.value = {
                    open: true,
                    title: strings.atencao,
                    type: 'alert',
                    message: strings.idNaoEncontrado,
                    options: [
                        {
                            title: strings.ok,
                            onClick: redirectInicio,
                        }

                    ],
                    onClose: redirectInicio
                }
                console.log(err)
            });

        return () => {
            if (socket.value) {
                socket.value.disconnect();
                socket.value = null;
            }
        }
    }, [id]);

    const enviarDadosUsuario = (dadosUsuario) => {
        socket.value.timeout(2000).emit(EVENTOS_SOCKET.DADOS_USUARIO, JSON.stringify(dadosUsuario), (error, response) => {
            console.log(error)
            console.log(response)
            if (error) {
                console.log('Timeout no envio dos dados do usuário. Tentando novamente...');
                enviarDadosUsuario(dadosUsuario);
                return;
            }
            if (response.status === 'ok') {
                console.log('Dados do usuário enviados com sucesso.');
                return;
            }
            console.log('Falha na envio dos dados do usuário. Tentando novamente...');
            enviarDadosUsuario(dadosUsuario);
        })

    }

    const iniciarConexaoWebsocket = () => {
        const options = {
            query: { idAgendamento: id },
            forceNew: false,
            extraHeaders: {
                ...getAuthorizationHeader()
            },
            // transports: ['websocket', 'polling'],
            reconnectionDelay: 5000, // defaults to 1000
            reconnectionDelayMax: 8000 // defaults to 5000
        };
        if (global.gConfig.url_base_socket_path !== '') {
            options.path = global.gConfig.url_base_socket_path;
        }
        socket.value = connect(global.gConfig.url_base_socket, options);

        const dadosUsuario = {
            nome: usuario.value.nome,
            // atendente: temPermissaoRBAC(usuario, PERMISSOES.INTERNO),
            id: usuario.value.idFuncionario,
            cpf: usuario.value.cpf,
            uf: usuario.value.estado,
            municipio: usuario.value.cidade,
        };

        socket.value.on("connect", () => {
            if (erroConexacaoSocket.value) {
                window.location.reload();
            }

            atendimentoEncerrado.value = agendamentoEvento.value.situacao === SITUACAO_AGENDAMENTO.SITUACAO.FINALIZADO;
            enviarDadosUsuario(dadosUsuario);
        });

        function handleErrors(err) {
            erroConexacaoSocket.value = true;

            const box = document.getElementById('box-evento');

            const buttons = box.getElementsByTagName('button');
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].disabled = 'disabled';
            }

            const inputs = box.getElementsByTagName('input');
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].disabled = 'disabled';
            }
        }

        socket.value.on('connect_error', err => handleErrors(err));
        socket.value.on('connect_failed', err => handleErrors(err));
        socket.value.on('disconnect', err => handleErrors(err));
    };

    return (
        <SttBox className={classes.wrapper}>
            {
                erroConexacaoSocket.value &&
                <SttBox className={classes.notificationBox}>
                    <SttNotification severity='error' className={classes.notificacao}>
                        <div dangerouslySetInnerHTML={{ __html: strings.avisoSemConexao }} />
                    </SttNotification>
                </SttBox>
            }
            <SttBox className={classes.wrapperEvento} id="box-evento">
                {
                    agendamentoEvento.value &&
                    <>
                        <SttHidden xsDown>
                            <SttBox className={listaUsuariosAberta.value ? classes.leftBox : classes.shortLeftBox}>
                                <PainelEsquerda />
                            </SttBox>
                        </SttHidden>
                        {/* <SttHidden smUp>
                            <SttBox className={listaUsuariosAberta.value ? classes.fullLeftBox : classes.shortLeftBox}>
                                <PainelEsquerda />
                            </SttBox>
                        </SttHidden> */}

                        <SttBox sx={{ border: ".05px solid #e9e9e9" }} />

                        <SttHidden xsDown>
                            <SttBox className={listaUsuariosAberta.value ? classes.rigthBox : classes.bigRigthBox}>
                                <Painel />
                            </SttBox>
                        </SttHidden>
                        <SttHidden smUp>
                            <SttBox className={listaUsuariosAberta.value ? classes.hiddenRigthBox : classes.fullRigthBox}>
                                <Painel />
                            </SttBox>
                        </SttHidden>
                    </>
                }
            </SttBox>
        </SttBox>
    );
}

export default HomeEvento;