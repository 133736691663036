import React from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import Interno from './interno';
import Manutencao from './erro/manutencao';
import Erro from './erro';
import { SttAlerta, SttLoading } from '@stt-componentes/core';
import alerta from '../signals/alerta';
import carregando from '../signals/carregando';
import { useSignals } from '@preact/signals-react/runtime';
import InternoEvento from './interno-evento';

const Evento = () => {
    const { id } = useParams();
    return <InternoEvento id={id} />;
}

const Home = () => {
    useSignals();

    return (
        <BrowserRouter basename={`/${global.gConfig.basename}`}>
            <Routes>
                <Route path="/erro" exact element={<Erro />} />
                {
                    global.gConfig.manutencao
                        ?
                        <Route path="*" element={<Manutencao />} />
                        :
                        <>
                            <Route path="/evento/:id" element={<Evento />} />
                            <Route path="/evento" element={<Evento />} />
                            <Route path="*" element={<Interno />} />
                        </>
                }
            </Routes>
            <SttAlerta {...alerta.value} />
            <SttLoading {...carregando.value} />
        </BrowserRouter>
    );
};

export default Home;
